<script>
import DynamicImage from "../../components/UI/DynamicImage/index.vue";
import {cropImage, getFlagSpan, shortUnits} from "../../utils/utils";
import SvgIcon from "../../components/UI/SvgIcon/index.vue";
import Tooltip from "@/components/UI/Tooltip/index.vue";

export default {
  name: "MarketResearchLegendItem",
  methods: {shortUnits, getFlagSpan, cropImage},
  components: {SvgIcon, DynamicImage, 'custom-tooltip': Tooltip},
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    colorByApp: {
      type: Object,
      default: () => ({})
    },
    colorByCountry: {
      type: Object,
      default: () => ({})
    },
    grayColor: {
      type: String,
      default: '#E5E5E5'
    },
    countryMode: {
      type: Boolean,
      default: false
    },
    percentProp: {
      type: String,
      default: 'installs_of_total_percent'
    },
    valueProp: {
      type: String,
      default: 'installs'
    }
  },
}
</script>

<template>
  <custom-tooltip :classes="'bg-none'" :style="{width: 'max-content', paddingRight: '30px'}">
    <template v-slot:slotTrigger>
      <div class="display-flex f-space-between" v-if="countryMode">
        <div class="display-flex">
          <div class="color_hint" :style="`background-color: ${colorByCountry[data.country.code] ?? grayColor}`"></div>
          <dynamic-image classes="flag ml-10"
                         :width="18"
                         :height="14"
                         :size="32"
                         :country-code="data.country.code"/>
          <span class="ml-10 country-title-short">{{ data.country.name }}</span>
        </div>
        <div class="ml-10 percent_hint">{{ Number(data[percentProp]).toFixed(1) }}%</div>
      </div>
      <div class="display-flex f-space-between" v-else>
        <div class="display-flex">
          <div class="color_hint" :style="`background-color: ${colorByApp[data.app.id] ?? grayColor}`"></div>
          <img class="ml-10" v-if="data.app.logo" :src="cropImage(data.app.logo, '24x24', data.app.store.key)" alt=""><div v-else style="width: 35px;"></div>
          <span class="ml-10 app-title-short">{{ data.app.title }}</span>
        </div>
        <div class="ml-10 percent_hint">{{ Number(data[percentProp]).toFixed(1) }}%</div>
      </div>
    </template>
    <template v-slot:slotContent>
      <div class="market-tooltip pie-tooltip">
        <div class="display-flex">
          <template v-if="countryMode">
            <div class="color_hint" :style="`background-color: ${colorByCountry[data.country.code] ?? grayColor}`"></div><span class="ml-10"><span v-html="getFlagSpan(data.country.code)"></span>{{ data.country.name }}</span>
          </template>
          <template v-else>
            <div class="color_hint" :style="`background-color: ${colorByApp[data.app.id] ?? grayColor}`"></div>
            <img class="ml-10" v-if="data.app.logo" :src="cropImage(data.app.logo, '24x24', data.app.store.key)" alt=""><div v-else style="width: 35px;"></div>
            <span class="ml-10 app-title-short">{{data.app.title}}</span>
          </template>
        </div>
        <div class="display-flex">
          <b>{{shortUnits(data[valueProp])}}</b><div class="ml-10 percent_hint">({{Number(data[percentProp]).toFixed(1)}}%)</div>
        </div>
      </div>
    </template>
  </custom-tooltip>
</template>

<style scoped lang="scss">

</style>