<script>
export default {
  name: "Dynamic",
  props: {
    value: {
      type: Number,
    },
    dynamic: {
      type: [Number, String],
      default: () => 0
    },
    width: {
      type: String,
      default: () => 'initial'
    },
    formatter: {
      type: Function,
      default: (value) => value
    },
    dynamicFormatter: {
      type: Function,
      default: (value) => value
    }
  }
}
</script>

<template>
<div class="dynamic_container" :style="{width: width}">
  <span class="dynamic_val" v-if="Math.abs(dynamic) !== 0" :class="{'green':dynamic > 0, 'red': dynamic < 0}">
    {{dynamicFormatter(Math.abs(dynamic))}}&nbsp;
    <template v-if="dynamic > 0">
      <img src="@/assets/svg/arrow-up-alt.svg" width="12" height="12"/>
    </template>
    <template v-else-if="dynamic < 0">
      <img src="@/assets/svg/arrow-down-alt.svg" width="12" height="12"/>
    </template>
  </span>
  <span v-else></span>

  <span>
    <span class="hidden_sign" v-if="dynamic === 0"><img src="@/assets/svg/arrow-up-alt.svg" width="12" height="12"/></span>
    <slot>{{formatter(value)}}</slot>
  </span>
</div>
</template>

<style scoped lang="scss">
.dynamic_container {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .green {
    color: var(--secondary-dark-green);
  }
  .red {
    color: var(--secondary-red);
  }

  .dynamic_val {
    font-size: 12px;
    font-weight: 400;
    margin-right: 5px;

    img {
      margin-left: -3px;
    }
  }

  > span {
    display: flex;
    align-items: center;
  }

  .hidden_sign {
    opacity: 0;
  }
}
</style>