<script>
import MarketResearchCountries from "./MarketResearchCountries.vue";
import {mapGetters} from "vuex";
import InfoTip from "../../components/InfoTip/InfoTip.vue";
import SimpleInfoTable from "../../components/SimpleInfoTable/SimpleInfoTable.vue";
import {convertDays, cropImage, formatDate, shortUnits} from "../../utils/utils";
import DynamicImage from "../../components/UI/DynamicImage/index.vue";
import Dynamic from "../../components/UI/Dynamic/Dynamic.vue";
import RatingTool from "../../components/DataTables/Tools/RatingTool/index.vue";
import ProgressBar from "../../components/UI/ProgressBar/index.vue";
import MarketResearch from "../../api/modules/market-research";
import CustomSelect from "../../components/Forms/CustomSelect/index.vue";
import SvgIcon from "../../components/UI/SvgIcon/index.vue";
import Breadcrumbs from "../../components/UI/Breadcrumbs.vue";
import MidMap from "./MidMap.vue";
import CustomSelectNew from "../../components/Forms/CustomSelectNew/index.vue";
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";

export default {
  name: "MarketResearch",
  props: {
    researchId: {
      type: String,
    }
  },
  methods: {
    formatDate,
    convertDays,
    shortUnits,
    cropImage,
    progressColor(percent) {
      //TODO fix duplicate code
      let color = '';
      if (percent >= 80) {
        color = '#5AAC80';
      } else if (percent >= 60) {
        color = '#6DD690';
      } else if (percent >= 40) {
        color = '#FCB852';
      } else if (percent >= 20) {
        color = '#F7D758';
      } else {
        color = '#EC5C3E';
      }

      return `background: linear-gradient(90deg, ${color} 0%, ${color} ${percent}%, #E1E8F2 ${percent}%, #E1E8F2 100%);`;
    },
    selectCategory(category) {
      this.reportData.selectedCategory = category;
      this.reportData.freeCategoryCharts = this.reportData.categoriesData[category.origin_id]?.['top-free'] ?? [];
      this.reportData.freeGrossingCharts = this.reportData.categoriesData[category.origin_id]?.['top-grossing'] ?? [];

      if (!this.reportData.freeCategoryCharts['top_' + this.freeTopSize]?.length) {
        this.freeTopSize = this.freeTopSize === 100 ? 50 : 100;
      }
      if (!this.reportData.freeGrossingCharts['top_' + this.grossingTopSize]?.length) {
        this.grossingTopSize = this.grossingTopSize === 100 ? 50 : 100;
      }
    },
    changeMapMode(mode) {
      this.ratingsMapMode = mode;
    },
    trClassCallback(item) {
      if (!item.app?.id) {
        return {};
      }

      return {'my_app': item.app.id === this.reportData.app.id};
    },
    formatItemAppTitle(item) {
      if (!item.app?.id) {
        return {};
      }

      if (item.app.id === this.reportData.app.id) {
        return `${item.app.title} <span class="myAppHint">(My App)</span>`
      }

      return item.app.title;
    },
    setDocumentTitle() {
      let title = this.reportData.research_info.app_data.name + ' ' + this.$route.meta.pageTitle + ' '
        + this.reportData.research_info.date_from + ' - ' + this.reportData.research_info.date_to;
      this.setPageTitle(title);
    },
  },
  components: {
    OrderRadasoButton,
    CustomSelectNew,
    MidMap,
    Breadcrumbs,
    SvgIcon,
    CustomSelect,
    MarketResearchCountries,
    ProgressBar,
    RatingTool,
    Dynamic,
    DynamicImage,
    SimpleInfoTable,
    InfoTip
  },
  data() {
    return {
      loaded: false,
      activeTab: "by_countries",
      activeAgeTop: 'youngest',

      mapChart: null,

      mapChartData: [],

      ratingsMapMode: 'votes',

      freeTopSize: 100,
      grossingTopSize: 100,

      reportData: {
        iap: null,
        updates: null,
        selectedCategory: null,
        selectedTop: 100,
        categories: [],
        categoriesData: {},
        topAppsByUpdateRate: [],
        topByUserRatings: [],
        topAppsByInstalls: [],
        topAppsByRevenue: [],
        topAppsIapHigh: [],
        topAppsIapLow: [],
        topAppsYoungest: [],
        topAppsOldest: [],
        freeCategoryCharts: [],
        freeGrossingCharts: []
      }
    }
  },

  computed: {
    allowedSize() {
      return this.windowWidth >= 768;
    },
    ...mapGetters([
      'isMobile',
      'sidebarIsCollapsed',
      'currentApp'
    ]),
    isAllowedRevenue() {
      return this.reportData.app.store.key !== 'GOOGLE_PLAY';
    },
  },

  async mounted() {
    let data = await MarketResearch.getMarketResearchData(this.researchId);
    this.reportData.topAppsByInstalls = data.top_apps_by_installs;
    this.reportData.topAppsByRevenue = data.top_apps_by_revenue;
    this.reportData.topByUserRatings = data.top_countries_by_votes;
    this.reportData.topAppsByUpdateRate = data.top_apps_by_update_frequency;
    this.reportData.topAppsIapHigh = data.top_apps_with_highest_iap;
    this.reportData.topAppsIapLow = data.top_apps_with_lowest_iap;
    this.reportData.topAppsYoungest = data.top_youngest_apps;
    this.reportData.topAppsOldest = data.top_oldest_apps;
    this.reportData.iap = data.iap;
    this.reportData.updates = data.updates;
    this.reportData.categories = data.categories;
    this.reportData.categoriesData = data.charts_data;
    this.reportData.countriesInstallsRevenueShare = data.countries_installs_revenue_share;
    this.reportData.appsInstallsRevenueShare = data.apps_installs_revenue_share;
    this.reportData.countriesRatingsVotesShare = data.countries_votes_share;

    this.reportData.historyDataCountries = data.history_by_countries;
    this.reportData.historyDataApps = data.history_by_apps;

    this.reportData.app = data.research.app;
    this.reportData.research_info = data.research;

    this.reportData.categories.forEach(category => {
      // if (app.category.name === category.name) {
      //   this.selectCategory(category);
      // }
    });
    if (!this.reportData.selectedCategory)
      this.selectCategory(data.categories[0]);

    this.setDocumentTitle();
    this.loaded = true;
  },
}
</script>

<template>
  <the-new-header/>
  <div class="page-content">
    <slot name="sidebar"></slot>
    <main :class="[{'collapsed': sidebarIsCollapsed}, {'full-width': isMobile}]">
      <div v-if="reportData.app" class="breadcrumbs-wrap">
        <breadcrumbs :items="[
            {title: '<h1 class=\'page-title\'>Market Research</h1>', to: {name: 'MarketResearch'}},
            {title: `<div class='display-flex'><img src='${cropImage(reportData.app.logo, '24x24', reportData.app.store.key)}'><b>&nbsp;${reportData.app.title}&nbsp;</b></div>`},
        ]"></breadcrumbs>

        <order-radaso-button class="order-radaso-wrap" :pre-selected="['full-aso-support', 'launch-support']" />
      </div>

      <div v-if="!allowedSize" class="display-flex f-j-center p-10 mt-20">
        Unsupported for your window screen width
      </div>
      <div class="market_research_container" v-if="loaded" v-show="allowedSize">
        <div class="display-flex f-space-between f-wrap">
          <div class="common-big-block-title" style="margin: 0; font-weight: bold">Competitors Marketing Research</div>
          <div style="color: var(--neutral-800)">{{formatDate(reportData.research_info.date_from, 'month-day-year')}} - {{formatDate(reportData.research_info.date_to, 'month-day-year')}}</div>
        </div>
        <div class="top_nav display-flex f-space-between">
          <div class="common-big-block-title" style="margin-top: 20px">Installs & Revenue Intelligence</div>
          <div class="tabs_switch display-flex">
            <div class="common-tab-title ml-10 cursor-pointer" @click="activeTab='by_countries'"
                 :class="{ active: activeTab === 'by_countries' }">By Countries
            </div>
            <div class="common-tab-title ml-10 cursor-pointer" @click="activeTab='by_apps'"
                 :class="{ active: activeTab === 'by_apps' }">By Apps
            </div>
          </div>
        </div>
        <div class="tabs_content_container">
          <div class="tabs_container">
            <MarketResearchCountries
                :tr-class-callback="trClassCallback"
                :format-item-app-title="formatItemAppTitle"

                :report-app="reportData.app"
                :revenue-is-allowed="isAllowedRevenue"
                :key="activeTab"
                :app-countries-mode="activeTab === 'by_countries' ? 'countries' : 'apps'"
                :date_from="new Date(reportData.research_info.date_from)"
                :date_to="new Date(reportData.research_info.date_to)"
                :countries-history="reportData.historyDataCountries"
                :apps-history="reportData.historyDataApps"
                :apps-installs-revenue-share="reportData.appsInstallsRevenueShare"
                :countries-installs-revenue-share="reportData.countriesInstallsRevenueShare"></MarketResearchCountries>
          </div>
        </div>
        <div class="tops_container display-flex">
          <div class="tops_content" :class="{'width-100-p-I': !isAllowedRevenue}">
            <div class="common-big-block-title">Top apps by Installs</div>
            <SimpleInfoTable
              :tr-class-callback="trClassCallback"
              :show-pagination="false"
              :columns="{
                0: {header: '', style: {width: '50px'}},
                1: {header: 'App', style: {width: '100%', minWidth: '250px', paddingLeft: '0'}, orientation: 'left'},
                2: {header: 'Installs', style: {'min-width': '100px'}},
              }"
              :items="reportData.topAppsByInstalls"
            >
              <template v-slot:headers="slotProps">
                <div>
                  {{ slotProps.header }}
                </div>
              </template>
              <template v-slot:items-0="slotProps">
                <div class="display-flex f-align-center f-j-center">
                  {{ slotProps.index + 1 }}
                </div>
              </template>
              <template v-slot:items-1="slotProps">
                <div class="display-flex f-align-center">
                  <img :src="cropImage(slotProps.item.app.logo, '32x32', slotProps.item.app.store.key)" alt="">
                  <span class="ml-10" v-html="formatItemAppTitle(slotProps.item)"></span>
                </div>
              </template>
              <template v-slot:items-2="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  {{ shortUnits(slotProps.item.installs) }}
                </div>
              </template>
            </SimpleInfoTable>
          </div>
          <div class="tops_content" v-if="isAllowedRevenue">
            <div class="common-big-block-title">Top apps by Revenue</div>
            <SimpleInfoTable
              :tr-class-callback="trClassCallback"
              :show-pagination="false"
              :columns="{
                0: {header: '', style: {width: '50px'}},
                1: {header: 'App', style: {width: '100%', minWidth: '250px', paddingLeft: '0'}, orientation: 'left'},
                2: {header: 'Revenue', style: {'min-width': '100px'}},
              }"
              :items="reportData.topAppsByRevenue"
            >
              <template v-slot:headers="slotProps">
                <div>
                  {{ slotProps.header }}
                </div>
              </template>
              <template v-slot:items-0="slotProps">
                <div class="display-flex f-align-center f-j-center">
                  {{ slotProps.index + 1 }}
                </div>
              </template>
              <template v-slot:items-1="slotProps">
                <div class="display-flex f-align-center">
                  <img :src="cropImage(slotProps.item.app.logo, '32x32', slotProps.item.app.store.key)" alt="">
                  <span class="ml-10" v-html="formatItemAppTitle(slotProps.item)"></span>
                </div>
              </template>
              <template v-slot:items-2="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  ${{ shortUnits(slotProps.item.revenue) }}
                </div>
              </template>
            </SimpleInfoTable>
          </div>
        </div>
        <div class="top_by_user_rating_container">
          <div class="common-big-block-title">Top Countries by Competitors User Ratings</div>
          <SimpleInfoTable
            :show-pagination="false"
            :columns="{
              0: {header: '', style: {width: '50px'}},
              1: {header: 'Countries', style: {width: '100%', minWidth: '250px', paddingLeft: '0'}, orientation: 'left'},
              2: {header: 'Avg. Comp. Rate', style: {'min-width': '170px'}},
              3: {header: 'Avg. Comp.<br>Ratings',style: {'min-width': '150px'}},
              4: {header: 'Country<br>Ratings Share', style: {'min-width': '180px'}},
              5: {
                  header: '<img src=\''+cropImage(reportData.app.logo, '24x24', reportData.app.store.key)+'\'><div class=\'ml-10\'>Rate</div>',
                  style: {'min-width': '135px'}
                },
              6: {
                  header: '<img src=\''+cropImage(reportData.app.logo, '24x24', reportData.app.store.key)+'\'><div class=\'ml-10\'>Ratings</div>',
                  style: {'min-width': '160px'}
                }
            }"
            :items="reportData.topByUserRatings"
          >
            <template v-slot:headers="slotProps">
              <div :class="{'text-right': [3, 4].includes(slotProps.index)}">
                <span :class="{'display-flex f-j-end f-align-center': [5, 6].includes(slotProps.index)}"
                      v-html="slotProps.header"></span>
              </div>
            </template>
            <template v-slot:items-0="slotProps">
              <div class="display-flex f-align-center f-j-center">
                {{ slotProps.index + 1 }}
              </div>
            </template>
            <template v-slot:items-1="slotProps">
              <div class="display-flex f-align-center">
                <dynamic-image classes="flag"
                               :width="18"
                               :height="14"
                               :size="32"
                               :country-code="slotProps.item.country.code"/>
                <span class="ml-10">{{ slotProps.item.country.name }}</span>
              </div>
            </template>
            <template v-slot:items-2="slotProps">
              <div class="display-flex f-align-center f-j-end">
                <rating-tool :rating="slotProps.item.avg_rating"/>
              </div>
            </template>
            <template v-slot:items-3="slotProps">
              <div class="display-flex f-align-center f-j-end">
                {{ shortUnits(slotProps.item.avg_votes) }}
              </div>
            </template>
            <template v-slot:items-4="slotProps">
              <div class="display-flex f-align-center f-space-between full-width">
                <progress-bar width="80px"
                              height="8px"
                              :version="2"
                              :fill-color="progressColor(slotProps.item.avg_votes_of_total_percent)"
                              bar-type="custom"/>
                <span class="ml-10">{{ Number(slotProps.item.avg_votes_of_total_percent).toFixed(1) }}%</span>
              </div>
            </template>
            <template v-slot:items-5="slotProps">
              <div class="ml-10">
                <dynamic :value="slotProps.item.app_rating.value"
                         v-if="slotProps.item.app_votes.value"
                         :dynamic="slotProps.item.app_rating.dynamic">
                  <rating-tool :rating="slotProps.item.app_rating.value"/>
                </dynamic>
                <div v-else>-</div>
              </div>
            </template>
            <template v-slot:items-6="slotProps">
              <div class="ml-10">
                <dynamic :value="slotProps.item.app_votes.value"
                         :formatter="(value) => {return shortUnits(value)}"
                         :dynamic-formatter="(value) => {return value + '%'}"
                         v-if="slotProps.item.app_votes.value"
                         :dynamic="slotProps.item.app_votes.dynamic_percent"/>
                <div v-else>-</div>
              </div>
            </template>
          </SimpleInfoTable>
        </div>
        <div class="avg_map_container">
          <div class="common-big-block-title">Average Competitors Rate of the World</div>
          <div class="display-flex">
            <div class="common-block-title cursor-pointer" :class="{'active': ratingsMapMode === 'votes'}" @click="changeMapMode('votes')">By Rating Market Share</div>
            <div class="common-block-title ml-10 cursor-pointer" :class="{'active': ratingsMapMode === 'ratings'}" @click="changeMapMode('ratings')">By Rate</div>
          </div>
          <div class="common-white-container">
            <mid-map
                :key="ratingsMapMode"
                :countries-ratings-votes-share="reportData.countriesRatingsVotesShare"
                :mode="ratingsMapMode"
                :min-percent="2"
            ></mid-map>
          </div>
        </div>
        <div class="category_positions" v-if="reportData.selectedCategory && reportData.categories.length"
        >
          <div class="common-big-block-title">Top Countries by Category Positions</div>
          <div>
            <custom-select-new
                :options="reportData.categories"
                :key="reportData.selectedCategory.id"
                :pre-selected="reportData.selectedCategory"
                value-field="id"
                label-field="name"
                :styles="{
                  label: {
                    width: '200px'
                  }
                }"
                @changed="selectCategory"
            ></custom-select-new>
          </div>
          <div class="tops_container display-flex f-align-start">
            <div class="tops_content" v-if="reportData.freeCategoryCharts['top_' + freeTopSize]?.length" :class="{'width-100-p-I': !reportData.freeGrossingCharts['top_' + grossingTopSize]?.length}">
              <div class="display-flex f-space-between align-center">
                <div class="common-big-block-title">Free Category Charts</div>
                <div class="display-flex">
                  <div class="common-btn-purple-transparent" :class="{active: freeTopSize === 50}" @click="freeTopSize = 50" v-if="reportData.freeCategoryCharts['top_' + 50]?.length">Top 50</div>
                  <div class="common-btn-purple-transparent ml-10" :class="{active: freeTopSize === 100}" @click="freeTopSize = 100" v-if="reportData.freeCategoryCharts['top_' + 100]?.length">Top 100</div>
                </div>
              </div>
              <SimpleInfoTable
                :per-page="10"
                :show-pagination="false"
                :columns="{
                  0: {header: '', style: {width: '50px'}},
                  1: {header: 'Countries', style: {width: '100%', minWidth: '250px', paddingLeft: '0', paddingRight: 0}, orientation: 'left'},
                  2: {header: '# Comp.<br>in Top ' + freeTopSize, style: {'min-width': '125px', paddingLeft: '0'}, sortBy: 'competitors_in_top'},
                  3: {header: 'Average<br>Position', style: {'min-width': '115px', paddingLeft: '0'}, sortBy: 'avg_pos'},
                  4: {header: cropImage(reportData.app.logo, '24x24', reportData.app.store.key), style: {}, sortBy: function (value) {return value.app_pos.value}}
                }"
                :default-sort-index="3"
                :default-sort-direction="'asc'"
                :items="reportData.freeCategoryCharts['top_' + freeTopSize]"
              >
                <template v-slot:headers="slotProps">
                  <div :class="{'text-right': [2, 3].includes(slotProps.index)}">
                    <span v-if="slotProps.index < 4" v-html="slotProps.header"/>
                    <span v-else class="display-flex f-align-center f-j-end"><img :src="slotProps.header"></span>
                  </div>
                </template>
                <template v-slot:items-0="slotProps">
                  <div class="display-flex f-align-center f-j-center">
                    {{ slotProps.index + 1 }}
                  </div>
                </template>
                <template v-slot:items-1="slotProps">
                  <div class="display-flex f-align-center">
                    <dynamic-image classes="flag"
                                   :width="18"
                                   :height="14"
                                   :size="32"
                                   :country-code="slotProps.item.country.code"/>
                    <span class="ml-10">{{ slotProps.item.country.name }}</span>
                  </div>
                </template>
                <template v-slot:items-2="slotProps">
                  <div class="display-flex f-align-center f-j-end">
                    {{ slotProps.item.competitors_in_top }}
                  </div>
                </template>
                <template v-slot:items-3="slotProps">
                  <div class="display-flex f-align-center f-j-end">
                    {{ slotProps.item.avg_pos }}
                  </div>
                </template>
                <template v-slot:items-4="slotProps">
                  <div>
                    <dynamic :value="slotProps.item.app_pos.value" :formatter="(value) => value ? value : '-'" :dynamic="slotProps.item.app_pos.dynamic"/>
                  </div>
                </template>
              </SimpleInfoTable>
            </div>
            <div class="tops_content" v-if="reportData.freeGrossingCharts['top_' + grossingTopSize]?.length"
                 :class="{'width-100-p-I': !reportData.freeCategoryCharts['top_' + freeTopSize]?.length}">
              <div class="display-flex f-space-between align-center">
                <div class="common-big-block-title">Grossing Category Charts</div>
                <div class="display-flex">
                  <div class="common-btn-purple-transparent" :class="{active: grossingTopSize === 50}" @click="grossingTopSize = 50" v-if="reportData.freeGrossingCharts['top_' + 50]?.length">Top 50</div>
                  <div class="common-btn-purple-transparent ml-10" :class="{active: grossingTopSize === 100}" @click="grossingTopSize = 100" v-if="reportData.freeGrossingCharts['top_' + 100]?.length">Top 100</div>
                </div>
              </div>
              <SimpleInfoTable
                :per-page="10"
                :show-pagination="false"
                :columns="{
                  0: {header: '', style: {width: '50px'}},
                  1: {header: 'Countries', style: {width: '100%', minWidth: '250px', paddingLeft: '0', paddingRight: 0}, orientation: 'left'},
                  2: {header: '# Comp.<br>in Top ' + grossingTopSize, style: {'min-width': '125px', paddingLeft: '0'}, sortBy: 'competitors_in_top'},
                  3: {header: 'Average<br>Position', style: {'min-width': '115px', paddingLeft: '0'}, sortBy: 'avg_pos'},
                  4: {header: cropImage(reportData.app.logo, '24x24', reportData.app.store.key), style: {}, sortBy: function (value) {return value.app_pos.value}},
                }"
                :default-sort-index="3"
                :default-sort-direction="'asc'"
                :items="reportData.freeGrossingCharts['top_' + grossingTopSize]"
              >
                <template v-slot:headers="slotProps">
                  <div :class="{'text-right': [2, 3].includes(slotProps.index)}">
                    <span v-if="slotProps.index < 4" v-html="slotProps.header"/>
                    <span v-else class="display-flex f-align-center f-j-end"><img :src="slotProps.header"></span>
                  </div>
                </template>
                <template v-slot:items-0="slotProps">
                  <div class="display-flex f-align-center f-j-center">
                    {{ slotProps.index + 1 }}
                  </div>
                </template>
                <template v-slot:items-1="slotProps">
                  <div class="display-flex f-align-center">
                    <dynamic-image classes="flag"
                                   :width="18"
                                   :height="14"
                                   :size="32"
                                   :country-code="slotProps.item.country.code"/>
                    <span class="ml-10">{{ slotProps.item.country.name }}</span>
                  </div>
                </template>
                <template v-slot:items-2="slotProps">
                  <div class="display-flex f-align-center f-j-end">
                    {{ slotProps.item.competitors_in_top }}
                  </div>
                </template>
                <template v-slot:items-3="slotProps">
                  <div class="display-flex f-align-center f-j-end">
                    {{ slotProps.item.avg_pos }}
                  </div>
                </template>
                <template v-slot:items-4="slotProps">
                  <div>
                    <dynamic :value="slotProps.item.app_pos.value" :formatter="(value) => value ? value : '-'" :dynamic="slotProps.item.app_pos.dynamic"/>
                  </div>
                </template>
              </SimpleInfoTable>
            </div>
          </div>
        </div>
        <div class="top_by_update_rate">
          <div class="display-flex f-space-between">
            <div class="common-big-block-title">Top Competitors by Update Rate</div>
            <div class="avg_upd_freq">Average Competitors Update Frequency - {{Number(this.reportData.research_info.avg_update_freq_d).toFixed()}}d</div>
          </div>
          <SimpleInfoTable
            :tr-class-callback="trClassCallback"
            :show-pagination="false"
            :columns="{
              0: {header: '', style: {width: '50px'}},
              1: {header: 'App', style: {width: '100%', minWidth: '250px', paddingLeft: '0'}, orientation: 'left'},
              2: {header: 'Upd. Speed', style: {'min-width': '170px'}},
              3: {header: 'Update<br>Frequency', style: {'min-width': '150px'}},
              4: {header: 'Rate', style: {'min-width': '150px'}},
              5: {header: 'Ratings', style: {'min-width': '145px'}}
            }"
            :items="reportData.topAppsByUpdateRate"
          >
            <template v-slot:headers="slotProps">
              <div :class="{'text-right': [3].includes(slotProps.index)}">
                <span v-html="slotProps.header"></span>
              </div>
            </template>
            <template v-slot:items-0="slotProps">
              <div class="display-flex f-align-center f-j-center">
                {{ slotProps.index + 1 }}
              </div>
            </template>
            <template v-slot:items-1="slotProps">
              <div class="display-flex f-align-center">
                <img :src="cropImage(slotProps.item.app.logo, '32x32', slotProps.item.app.store.key)" alt="">
                <span class="ml-10" v-html="formatItemAppTitle(slotProps.item)"></span>
              </div>
            </template>
            <template v-slot:items-2="slotProps">
              <div class="display-flex f-align-center f-j-end">
                <progress-bar width="80px"
                              height="8px"
                              :version="2"
                              :fill-color="progressColor(slotProps.item.percent_update_freq_between_min_max)"
                              bar-type="custom"/>
              </div>
            </template>
            <template v-slot:items-3="slotProps">
              <div class="display-flex f-align-center f-j-end">
                {{ slotProps.item.update_frequency_d }}d
              </div>
            </template>
            <template v-slot:items-4="slotProps">
              <div class="display-flex f-align-center f-j-end">
                <rating-tool :rating="slotProps.item.avg_rating"/>
              </div>
            </template>
            <template v-slot:items-5="slotProps">
              <div class="display-flex f-align-center f-j-end">
                {{ shortUnits(slotProps.item.sum_votes) }}
              </div>
            </template>
          </SimpleInfoTable>
        </div>
        <div v-if="loaded && (reportData.iap.min_from || reportData.iap.max_to)">
          <div class="common-big-block-title">Competitors Pricing Intelligence</div>
          <div class="info_tips_container display-flex f-space-between f-wrap">
            <InfoTip r-margin="10px">
              <template v-slot:tipContent>
                ${{ reportData.iap.avg_from }}
              </template>
              <template v-slot:tipTitle>
                Average from
              </template>
              <template v-slot:inTip>
                <div class="info_top_logo">
                  <dynamic :dynamic="reportData.iap.app_avg_from_percent" :dynamic-formatter="function (value) {return value + '%'}"></dynamic>
                  <custom-tooltip :style="{width: 'max-content'}">
                    <template v-slot:slotTrigger>
                      <div class="display-flex">
                        <img :src="cropImage(reportData.app.logo, '24x24', reportData.app.store.key)"/>
                      </div>
                    </template>
                    <template v-slot:slotContent>
                      <span class="info_tip_tooltip">${{reportData.iap.app_avg_from ?? 0}}</span>
                    </template>
                  </custom-tooltip>
                </div>
              </template>
            </InfoTip>
            <InfoTip r-margin="10px">
              <template v-slot:tipContent>
                ${{ reportData.iap.avg_to }}
              </template>
              <template v-slot:tipTitle>
                Average to
              </template>
              <template v-slot:inTip>
                <div class="info_top_logo">
                  <dynamic :dynamic="reportData.iap.app_avg_to_percent" :dynamic-formatter="function (value) {return value + '%'}"></dynamic>
                  <custom-tooltip :style="{width: 'max-content'}">
                    <template v-slot:slotTrigger>
                      <div class="display-flex">
                        <img :src="cropImage(reportData.app.logo, '24x24', reportData.app.store.key)"/>
                      </div>
                    </template>
                    <template v-slot:slotContent>
                      <span class="info_tip_tooltip">${{reportData.iap.app_avg_to ?? 0}}</span>
                    </template>
                  </custom-tooltip>
                </div>
              </template>
            </InfoTip>
            <InfoTip r-margin="10px">
              <template v-slot:tipContent>
                ${{ reportData.iap.min_from }}/${{ reportData.iap.max_from }}
              </template>
              <template v-slot:tipTitle>
                Min/Max iAP From
              </template>
            </InfoTip>
            <InfoTip>
              <template v-slot:tipContent>
                ${{ reportData.iap.min_to }}/${{ reportData.iap.max_to }}
              </template>
              <template v-slot:tipTitle>
                Min/Max iAP To
              </template>
            </InfoTip>
          </div>
        </div>
        <div class="tops_container display-flex" v-if="loaded && (reportData.iap.min_from || reportData.iap.max_to)">
          <div class="tops_content">
            <div class="common-big-block-title">Top apps with the highest iAP</div>
            <SimpleInfoTable
              :tr-class-callback="trClassCallback"
              :show-pagination="false"
              :columns="{
                0: {header: 'App', style: {width: '100%', minWidth: '250px', paddingRight: '0'}},
                1: {header: 'Avg. iAP from', style: {'min-width': '125px', paddingLeft: '0'}},
                2: {header: 'Avg. iAP to', style: {'min-width': '105px', paddingLeft: '0'}},
              }"
              :items="reportData.topAppsIapHigh"
            >
              <template v-slot:headers="slotProps">
                <div>
                  {{ slotProps.header }}
                </div>
              </template>
              <template v-slot:items-0="slotProps">
                <div class="display-flex f-align-center">
                  <img :src="cropImage(slotProps.item.app.logo, '32x32', slotProps.item.app.store.key)" alt="">
                  <span class="ml-10" v-html="formatItemAppTitle(slotProps.item)"></span>
                </div>
              </template>
              <template v-slot:items-1="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  ${{ slotProps.item.avg_iap_from ?? 0 }}
                </div>
              </template>
              <template v-slot:items-2="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  ${{ slotProps.item.avg_iap_to ?? 0 }}
                </div>
              </template>
            </SimpleInfoTable>
          </div>
          <div class="tops_content">
            <div class="common-big-block-title">Top apps with the lowest iAP</div>
            <SimpleInfoTable
              :tr-class-callback="trClassCallback"
              :show-pagination="false"
              :columns="{
                0: {header: 'App', style: {width: '100%', minWidth: '250px', paddingRight: '0'}},
                1: {header: 'Avg. iAP from', style: {'min-width': '125px', paddingLeft: '0'}},
                2: {header: 'Avg. iAP to', style: {'min-width': '105px', paddingLeft: '0'}},
              }"
              :items="reportData.topAppsIapLow"
            >
              <template v-slot:headers="slotProps">
                <div>
                  {{ slotProps.header }}
                </div>
              </template>
              <template v-slot:items-0="slotProps">
                <div class="display-flex f-align-center">
                  <img :src="cropImage(slotProps.item.app.logo, '32x32', slotProps.item.app.store.key)" alt="">
                  <span class="ml-10" v-html="formatItemAppTitle(slotProps.item)"></span>
                </div>
              </template>
              <template v-slot:items-1="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  ${{ slotProps.item.avg_iap_from ?? 0 }}
                </div>
              </template>
              <template v-slot:items-2="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  ${{ slotProps.item.avg_iap_to ?? 0 }}
                </div>
              </template>
            </SimpleInfoTable>
          </div>
        </div>
        <div v-if="loaded">
          <div class="common-big-block-title">Competitors Age Intelligence</div>
          <div class="info_tips_container display-flex f-space-between f-wrap">
            <InfoTip r-margin="10px">
              <template v-slot:tipContent>
                {{ formatDate(reportData.updates.avg_release_date, 'month-day-year') }}
              </template>
              <template v-slot:tipTitle>
                Avg First Release Date
              </template>
              <template v-slot:inTip>
                <div class="info_top_logo">
                  <custom-tooltip :style="{width: 'max-content'}">
                    <template v-slot:slotTrigger>
                      <div class="display-flex">
                        <img :src="cropImage(reportData.app.logo, '24x24', reportData.app.store.key)"/>
                      </div>
                    </template>
                    <template v-slot:slotContent>
                      <span class="info_tip_tooltip">{{formatDate(reportData.updates.app_release_date, 'month-day-year')}}</span>
                    </template>
                  </custom-tooltip>
                </div>
              </template>
            </InfoTip>
            <InfoTip r-margin="10px">
              <template v-slot:tipContent>
                {{ convertDays(reportData.updates.avg_age_days) }}
              </template>
              <template v-slot:tipTitle>
                Avg App Age
              </template>
              <template v-slot:inTip>
                <div class="info_top_logo">
                  <dynamic :dynamic="reportData.updates.app_age_days_percent" :dynamic-formatter="function (value) {return value + '%'}"></dynamic>
                  <custom-tooltip :style="{width: 'max-content'}">
                    <template v-slot:slotTrigger>
                      <div class="display-flex">
                        <img :src="cropImage(reportData.app.logo, '24x24', reportData.app.store.key)"/>
                      </div>
                    </template>
                    <template v-slot:slotContent>
                      <span class="info_tip_tooltip">{{convertDays(reportData.updates.app_age_days)}}</span>
                    </template>
                  </custom-tooltip>
                </div>
              </template>
            </InfoTip>
            <InfoTip r-margin="10px">
              <template v-slot:tipContent>
                {{ convertDays(reportData.updates.min_age_days) }}
              </template>
              <template v-slot:tipTitle>
                Min App Age
              </template>
            </InfoTip>
            <InfoTip>
              <template v-slot:tipContent>
                {{ convertDays(reportData.updates.max_age_days) }}
              </template>
              <template v-slot:tipTitle>
                Max App Age
              </template>
            </InfoTip>
          </div>
        </div>
        <div class="top_10_container">
          <div class="display-flex">
            <div class="common-big-block-title cursor-pointer" :class="{'not_active_tab':activeAgeTop!=='youngest'}"
                 @click="activeAgeTop='youngest'">Top 10 Youngest Competitors
            </div>
            <div class="common-big-block-title ml-10 cursor-pointer" :class="{'not_active_tab':activeAgeTop!=='oldest'}"
                 @click="activeAgeTop='oldest'">Top 10 Oldest Competitors
            </div>
          </div>
          <div v-show="activeAgeTop === 'youngest'">
            <SimpleInfoTable
              :tr-class-callback="trClassCallback"
              :show-pagination="false"
              :columns="{
                0: {header: '', style: {width: '50px'}},
                1: {header: 'App', style: {width: '100%', minWidth: '250px', paddingLeft: '0'}, orientation: 'left'},
                2: {header: 'App Age', style: {'min-width': '200px'}},
                3: {header: 'First Release', style: {'min-width': '150px'}},
                4: {header: 'Rate', style: {'min-width': '95px'}},
                5: {header: 'Ratings', style: {'min-width': '100px'}},
                6: {header: 'Installs', style: {'min-width': '110px'}},
                7: {header: 'Revenue', style: {'min-width': '110px'}, hidden: !this.isAllowedRevenue}
              }"
              :items="reportData.topAppsYoungest"
            >
              <template v-slot:headers="slotProps">
                <div>
                  <span :class="{'display-flex f-j-end f-align-center': [5, 6].includes(slotProps.index)}"
                        v-html="slotProps.header"></span>
                </div>
              </template>
              <template v-slot:items-0="slotProps">
                <div class="display-flex f-align-center f-j-center">
                  {{ slotProps.index + 1 }}
                </div>
              </template>
              <template v-slot:items-1="slotProps">
                <div class="display-flex f-align-center">
                  <img :src="cropImage(slotProps.item.app.logo, '32x32', slotProps.item.app.store.key)" alt="">
                  <span class="ml-10" v-html="formatItemAppTitle(slotProps.item)"></span>
                </div>
              </template>
              <template v-slot:items-2="slotProps">
                <div class="display-flex f-align-center f-space-between full-width">
                  <progress-bar width="80px"
                                height="8px"
                                :version="2"
                                :fill-color="progressColor(slotProps.item.percent_age_days_between_min_max)"
                                bar-type="custom"/>
                  <span class="ml-10">{{ convertDays(slotProps.item.age_days) }}</span>
                </div>
              </template>
              <template v-slot:items-3="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  {{ formatDate(slotProps.item.release_date, 'month-day-year') }}
                </div>
              </template>
              <template v-slot:items-4="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  <rating-tool :rating="slotProps.item.avg_rating"/>
                </div>
              </template>
              <template v-slot:items-5="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  {{ shortUnits(slotProps.item.sum_votes) }}
                </div>
              </template>
              <template v-slot:items-6="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  {{ shortUnits(slotProps.item.sum_installs) }}
                </div>
              </template>
              <template v-slot:items-7="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  {{ shortUnits(slotProps.item.sum_revenue) }}
                </div>
              </template>
            </SimpleInfoTable>
          </div>
          <div v-show="activeAgeTop === 'oldest'">
            <SimpleInfoTable
              :tr-class-callback="trClassCallback"
              :show-pagination="false"
              :columns="{
                0: {header: '', style: {width: '50px'}},
                1: {header: 'App', style: {width: '100%', minWidth: '250px', paddingLeft: '0'}, orientation: 'left'},
                2: {header: 'App Age', style: {'min-width': '200px'}},
                3: {header: 'First Release', style: {'min-width': '150px'}},
                4: {header: 'Rate', style: {'min-width': '95px'}},
                5: {header: 'Ratings', style: {'min-width': '100px'}},
                6: {header: 'Installs', style: {'min-width': '110px'}},
                7: {header: 'Revenue', style: {'min-width': '110px'}, hidden: !this.isAllowedRevenue},
              }"
              :items="reportData.topAppsOldest"
            >
              <template v-slot:headers="slotProps">
                <div>
                  <span :class="{'display-flex f-j-end f-align-center': [5, 6].includes(slotProps.index)}"
                        v-html="slotProps.header"></span>
                </div>
              </template>
              <template v-slot:items-0="slotProps">
                <div class="display-flex f-align-center f-j-center">
                  {{ slotProps.index + 1 }}
                </div>
              </template>
              <template v-slot:items-1="slotProps">
                <div class="display-flex f-align-center">
                  <img :src="cropImage(slotProps.item.app.logo, '32x32', slotProps.item.app.store.key)" alt="">
                  <span class="ml-10" v-html="formatItemAppTitle(slotProps.item)"></span>
                </div>
              </template>
              <template v-slot:items-2="slotProps">
                <div class="display-flex f-align-center f-space-between full-width">
                  <progress-bar width="80px"
                                height="8px"
                                :version="2"
                                :fill-color="progressColor(slotProps.item.percent_age_days_between_min_max)"
                                bar-type="custom"/>
                  <span class="ml-10">{{ convertDays(slotProps.item.age_days) }}</span>
                </div>
              </template>
              <template v-slot:items-3="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  {{ formatDate(slotProps.item.release_date, 'month-day-year') }}
                </div>
              </template>
              <template v-slot:items-4="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  <rating-tool :rating="slotProps.item.avg_rating"/>
                </div>
              </template>
              <template v-slot:items-5="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  {{ shortUnits(slotProps.item.sum_votes) }}
                </div>
              </template>
              <template v-slot:items-6="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  {{ shortUnits(slotProps.item.sum_installs) }}
                </div>
              </template>
              <template v-slot:items-7="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  {{ shortUnits(slotProps.item.sum_revenue) }}
                </div>
              </template>
            </SimpleInfoTable>
          </div>
        </div>
      </div>
      <div v-else>
        <progress-indicator>Loading Marketing Research...</progress-indicator>
      </div>
    </main>
  </div>
</template>

<style scoped lang="scss">
.breadcrumbs-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 24px;
}

.info_tip_tooltip {
  font-size: 18px;
  font-weight: 400;
  padding: 5px;
  color: var(--neutral-900);
}

.market_research_container {
  padding: 24px;
  overflow: hidden;

  .tabs_switch {
    .common-tab-title {
      font-size: 18px;
    }
  }

  .info_top_logo {
    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
  }

  .avg_map_container {
    .common-block-title {
      font-size: 18px;
    }
    .common-block-title:not(.active) {
      opacity: 0.33;
    }
  }

  .tops_container {
    align-items: flex-start;

    .tops_content {
      width: 50%;

      &:nth-of-type(1) {
        margin-right: 12px;
      }

      &:nth-of-type(2) {
        margin-left: 12px;
      }

      > div:first-child {
        margin-top: 40px;
        margin-bottom: 20px;

        .common-big-block-title {
          margin: 0;
        }

        .common-btn-purple-transparent:not(.active) {
          color: var(--neutral-600);
          border-color: var(--neutral-600);
        }
      }

      &.width-100-p-I {
        margin: 0;
      }
    }
  }

  .tops_container.category_positions {
    .tops_content {
      > div:first-child {
        margin-top: 20px;
        margin-bottom: 15px;
      }
    }
  }

  .top_by_update_rate {
    > div:first-child {
      margin-top: 40px;
      margin-bottom: 20px;

      .common-big-block-title {
        margin: 0;
      }

      .avg_upd_freq {
        color: var(--neutral-700);
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .common-big-block-title {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .avg_map_container {
    .common-white-container {
      padding: 15px;
      margin-top: 15px;
    }
  }

  .not_active_tab {
    opacity: 0.33;
  }

  .tip {
    flex: 20%;
  }
}

.market_research_container {
  @media (max-width: 1400px) {
    .tip {
      flex: initial;
      width: calc(50% - 10px);
      margin: 20px 0 0 0 !important;

      &:nth-of-type(2n - 1) {
        margin-right: 10px !important;
      }
      &:nth-of-type(2n) {
        margin-left: 10px !important;
      }
    }
  }

  @media (max-width: 1250px) {
    .tops_container {
      flex-direction: column;

      .tops_content {
        width: 100% !important;
        margin: 0 !important;
      }
    }
  }

  @media (max-width: 1024px) {
    .info_tips_container {
      flex-direction: column;

      .tip {
        width: 100% !important;
        margin: 20px 0 0 0 !important;
      }
    }
  }
}
</style>
<style lang="scss">
.market_research_container {
  padding: 24px;

  tr.my_app {
    background: var(--neutral-300);

    .myAppHint {
      color: var(--neutral-600, #A3B0C5);
    }
  }
}
</style>