<script>
export default {
  name: "InfoTip",
  props: {
    width: {
      type: String,
      default: "max-content"
    },
    rMargin: {
      type: String,
      default: "0px"
    },
  }
}
</script>

<template>
  <div class="tip common-white-container" :style="{minWidth: width, margin: `0 ${rMargin} 0 0`}">
    <div class="tip_content">
      <slot name="tipContent"></slot>
    </div>
    <div class="tip_title">
      <slot name="tipTitle"></slot>
    </div>
    <slot name="inTip"></slot>
  </div>
</template>

<style scoped lang="scss">
.tip {
  border-radius: 8px;
  padding: 24px;
  position: relative;
}
.tip_title {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: var(--neutral-900);
}
.tip_content {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  color: var(--neutral-900);
}
</style>