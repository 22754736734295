<script>
import MapCharts from "highcharts/modules/map";
import Highcharts from "highcharts";
import {getFlagSpan, shortUnits} from "../../utils/utils";
import HighMaps from "highcharts/highmaps";

export default {
  name: "TopMap",
  props: {
    countriesInstallsRevenueShare: {
      type: Array,
      default: () => []
    },
    appsByCountryIndex: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: String,
      default: 'installs'
    },
    minPercent: {
      type: Number,
      default: 1
    }
  },
  mounted() {
    this.makeMapChart();
  },
  methods: {
    makeMapChart() {
      MapCharts(Highcharts);

      const map = require("@highcharts/map-collection/custom/world.geo.json");

      let prop = this.mode === 'installs' ? 'installs' : 'revenue';
      let percentProp = this.mode === 'installs' ? 'installs_of_total_percent' : 'revenue_of_total_percent';

      let data = [];
      this.countriesInstallsRevenueShare.forEach(item => {
        if (!item[prop]) {
          return;
        }

        data.push([
          item.country.code.toLowerCase(),
          item[percentProp],
        ]);
      });

      const minValue = Math.min(...data.map(item => item[1]));
      const maxValue = Math.max(...data.map(item => item[1]));

      const appsByCountryIndex = this.appsByCountryIndex;
      const mode = this.mode;
      const minProp = this.minPercent;
      const formatterCallback = function () {
        const data = appsByCountryIndex[this.point['hc-key']];
        if (!data) {
          return false;
        }

        let html = '<div class="market-tooltip top-map-tooltip">';

        html += '<div class="display-flex bnt-border f-space-between tooltip-title">';
        html += `<div class="display-flex">${getFlagSpan(data.country.code)}${data.country.name}&nbsp;-&nbsp;<b>${shortUnits(data[prop])}</b>&nbsp;<div class="percent_hint">(${data[percentProp]}%)</div></div>`;
        html += `<div class="purple-hint ml-10">${mode === 'installs' ? 'Installs' : 'Revenue'}</div>`;
        html += '</div>';

        let otherValue = 0;
        let otherValuePercent = 0;

        data.apps.sort((a, b) => {return b[prop] - a[prop]}).forEach((app, index) => {
          if (!app[prop]) {
            return;
          }
          let percent = Number((app[prop] / data[prop]) * 100);
          if (percent <= minProp) {
            otherValue += app[prop];
            otherValuePercent += percent;
            return;
          }

          html += '<div class="app-row display-flex f-space-between">';

          html += '<div class="display-flex">';
          html += `<div>${index + 1}</div>`;
          html += `<div><img height="20" width="20" src="${app.app.logo}"></div>`;
          html += `<div>${app.app.title}</div>`;
          html += '</div>';

          html += '<div class="display-flex">'
          html += `<div>${shortUnits(app[prop])}</div>`;
          html += `<div class="ml-10">(${percent.toFixed(1)}%)</div>`;
          html += '</div>'

          html += '</div>';
        });

        if (otherValue) {
          html += '<div class="app-row display-flex f-space-between">';

          html += '<div class="display-flex">';
          html += `<div>-</div>`;
          html += `<div></div>`;
          html += `<div>Other</div>`;
          html += '</div>';

          html += '<div class="display-flex">'
          html += `<div>${shortUnits(otherValue)}</div>`;
          html += `<div class="ml-10">(${Number(otherValuePercent).toFixed(1)}%)</div>`;
          html += '</div>'

          html += '</div>';
        }

        html += '</div>';

        return html;
      }

      const options = {
        title: {
          text: null
        },
        colorAxis: {
          min: minValue,
          max: maxValue,
          minColor: "#93d1d5",
          maxColor: "#454abb"
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          "distance": 10,
          "padding": 0,
          "outside": false,
          "useHTML": true,
          "backgroundColor": "transparent",
          "borderWidth": 0,
          "borderRadius": 4,
          "shadow": false,
          "shape": "square",
          "hideDelay": 0,
          formatter: formatterCallback
        },
        series: [
          {
            mapData: map,
            data: data,
            dataLabels: {
              enabled: false,
            }
          }
        ],
        mapNavigation: {
          enabled: false,
          buttonOptions: {
            verticalAlign: "bottom"
          }
        }
      };

      this.chart = new HighMaps["Map"](this.$refs.map_chart, options);
    },
    onResize() {
      if (window.researchMidMapResizeTimeout) {
        clearTimeout(window.researchMidMapResizeTimeout);
      }
      window.researchMidMapResizeTimeout = setTimeout(() => {
        this.makeMapChart();
      }, 100);
    }
  },
  unmounted() {
    if (this.chart) {
      this.chart.destroy();
    }
    if (window.researchMidMapResizeTimeout) {
      clearTimeout(window.researchMidMapResizeTimeout);
    }
    window.removeEventListener('resize', this.onResize);
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  }
}
</script>

<template>
  <div class="map_chart_container">
    <div ref="map_chart" style="height: 450px"></div>
  </div>
</template>

<style scoped lang="scss">
.map_chart_container {
  > div {
    overflow: visible !important;
  }
}
</style>
<style lang="scss">
.market-tooltip.top-map-tooltip {
  .tooltip-title {
    font-size: 15px;
    font-weight: 400;
    color: var(--neutral-800);
    margin-top: -5px;

    .flag {
      margin-left: 0;
      margin-right: 7px;
    }

    b {
      font-weight: 600;
    }
    .percent_hint {
      color: var(--neutral-700);
    }
  }
  .bnt-border {
    border-bottom: 1px solid var(--neutral-400);
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .purple-hint {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-default);
  }

  .app-row {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    color: var(--neutral-800);

    > :nth-child(1) {
      align-items: center;

      > :nth-child(1) {
        width: 10px;
        text-align: center;
      }
      > :nth-child(2) {
        width: 30px;
        height: 20px;
        margin-left: 15px;
      }
      > :nth-child(3) {
        width: 190px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    > :nth-child(2) {
      margin-left: 20px;

      > :nth-child(1) {
        font-weight: 600;
      }
      > :nth-child(2) {
        color: var(--neutral-700);
      }
    }
  }
}
</style>