<script>
import MapCharts from "highcharts/modules/map";
import Highcharts from "highcharts";
import {getFlagSpan, shortUnits} from "../../utils/utils";
import HighMaps from "highcharts/highmaps";

export default {
  name: "MidMap",
  props: {
    countriesRatingsVotesShare: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: 'votes'
    },
    minPercent: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      appsByCountryIndex: {},
    }
  },
  mounted() {
    this.countriesRatingsVotesShare.forEach(item => {
      if (!item.sum_votes && !item.avg_rating) {
        return;
      }

      this.appsByCountryIndex[item.country.code.toLowerCase()] = {
        country: item.country,
        apps: item.byApps,
        sum_votes: item.sum_votes,
        avg_rating: item.avg_rating,
        avg_rating_percent_between_min_max: Number(item.avg_rating_percent_between_min_max).toFixed(1),
        sum_votes_percent_between_min_max: Number(item.sum_votes_percent_between_min_max).toFixed(1),
        sum_votes_of_total_percent: Number(item.sum_votes_of_total_percent).toFixed(1),
      };
    });

    this.makeMapChart();
  },
  methods: {
    makeMapChart() {
      MapCharts(Highcharts);

      const map = require("@highcharts/map-collection/custom/world.geo.json");

      let prop = this.mode === 'votes' ? 'sum_votes' : 'avg_rating';
      let percentProp = this.mode === 'votes' ? 'sum_votes_percent_between_min_max' : 'avg_rating_percent_between_min_max';

      let data = [];
      this.countriesRatingsVotesShare.forEach(item => {
        if (!item[prop]) {
          return;
        }

        data.push([
          item.country.code.toLowerCase(),
          item[percentProp],
        ]);
      });

      const minValue = Math.min(...data.map(item => item[1]));
      const maxValue = Math.max(...data.map(item => item[1]));

      const appsByCountryIndex = this.appsByCountryIndex;
      const minProp = this.minPercent;
      const formatterCallback = function () {
        const data = appsByCountryIndex[this.point['hc-key']];
        if (!data) {
          return false;
        }

        let html = '<div class="market-tooltip mid-map-tooltip">';

        html += '<div class="display-flex bnt-border f-space-between tooltip-title">';
        html += `<div class="display-flex">Avg. Rate/Ratings in&nbsp;${getFlagSpan(data.country.code)}&nbsp;${data.country.name}</div>`;
        html += `<div class="ml-10 values_cell"><span class="ratings"><svg class="svg-icons rating-icon"><use xlink:href="#star"></use></svg>${Number(data.avg_rating).toFixed(1)}</span><span class="separator">&nbsp;/&nbsp;</span><span class="votes">${shortUnits(data.sum_votes)}</span></div>`;
        html += '</div>';

        let otherValueVotes = 0;
        let otherValueRatings = 0;
        let otherValueRatingsCount = 0;
        let i = 1;

        data.apps.sort((a, b) => b[prop] - a[prop]).forEach((app, index) => {
          let percent = Number((app['sum_votes'] / data['sum_votes']) * 100);
          if (percent <= minProp) {
            otherValueVotes += app['sum_votes'];
            otherValueRatings += app['avg_rating'];
            otherValueRatingsCount += 1;
            return;
          }

          html += '<div class="app-row display-flex f-space-between bnt-border">';

          html += '<div class="display-flex">';
          html += `<div>${i++}</div>`;
          html += `<div><img height="20" width="20" src="${app.app.logo}"></div>`;
          html += `<div>${app.app.title}</div>`;
          html += '</div>';

          html += '<div class="values_cell">'
          html += `<div class="ratings"><svg class="svg-icons rating-icon"><use xlink:href="#star"></use></svg>${Number(app['avg_rating']).toFixed(1)}</div>`;
          html += `<div class="separator">&nbsp;/&nbsp;</div>`;
          html += `<div class="votes">${shortUnits(app['sum_votes'])}</div>`;
          html += '</div>'

          html += '</div>';
        });

        if (otherValueVotes) {
          html += '<div class="app-row display-flex f-space-between">';

          html += '<div class="display-flex">';
          html += `<div>-</div>`;
          html += `<div></div>`;
          html += `<div>Other</div>`;
          html += '</div>';

          html += '<div class="values_cell">'
          html += `<div class="ratings"><svg class="svg-icons rating-icon"><use xlink:href="#star"></use></svg>${Number(otherValueRatings / otherValueRatingsCount).toFixed(1)}</div>`;
          html += `<div class="separator">&nbsp;/&nbsp;</div>`;
          html += `<div class="votes">${shortUnits(otherValueVotes)}</div>`;
          html += '</div>'

          html += '</div>';
        }

        html += '</div>';

        return html;
      }

      const options = {
        title: {
          text: null
        },
        colorAxis: {
          min: minValue,
          max: maxValue,
          minColor: "#93d1d5",
          maxColor: "#454abb"
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          "distance": 10,
          "padding": 0,
          "outside": false,
          "useHTML": true,
          "backgroundColor": "transparent",
          "borderWidth": 0,
          "borderRadius": 4,
          "shadow": false,
          "shape": "square",
          "hideDelay": 0,
          formatter: formatterCallback
        },
        series: [
          {
            mapData: map,
            data: data,
            dataLabels: {
              enabled: false,
            }
          }
        ],
        mapNavigation: {
          enabled: false,
          buttonOptions: {
            verticalAlign: "bottom"
          }
        }
      };

      this.chart = new HighMaps["Map"](this.$refs.map_chart, options);
    },
    onResize() {
      if (window.researchMidMapResizeTimeout) {
        clearTimeout(window.researchMidMapResizeTimeout);
      }
      window.researchMidMapResizeTimeout = setTimeout(() => {
        this.chart.reflow();
      }, 100);
    }
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  }
}
</script>

<template>
  <div class="map_chart_container">
    <div ref="map_chart" style="height: 450px"></div>
  </div>
</template>

<style scoped lang="scss">
.map_chart_container {
  > div {
    overflow: visible !important;
  }
}
</style>
<style lang="scss">
.market-tooltip.mid-map-tooltip {
  border-radius: 8px;

  .bnt-border {
    border-bottom: 1px solid var(--neutral-400);
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .purple-hint {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-default);
  }

  .app-row {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    color: var(--neutral-800);

    &:last-child {
      border-bottom: none;
      margin-bottom: -5px;
    }

    > :nth-child(1) {
      align-items: center;

      > :nth-child(1) {
        width: 10px;
        text-align: center;
      }
      > :nth-child(2) {
        width: 30px;
        height: 20px;
        margin-left: 15px;
      }
      > :nth-child(3) {
        width: 190px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    > :nth-child(2) {
      margin-left: 20px;
    }
  }

  .values_cell {
    display: flex;
    max-width: 110px;
    min-width: 110px;
    align-items: center;
    justify-content: space-between;

    .ratings {
      color: var(--neutral-900);
      font-weight: 400;
      text-align: right;

      svg {
        margin-right: 5px;
        margin-bottom: 4px;
      }
    }
    .votes {
      color: var(--neutral-800);
      font-weight: 400;
      width: 70px !important;
      text-align: left;
    }
  }

  .tooltip-title {
    font-size: 15px;
    font-weight: 600;
    color: var(--neutral-800);
    margin-top: -10px;

    padding-top: 10px;
    padding-bottom: 15px;
  }

}
</style>